import TablaGeneral from "../../components/TablaGeneral";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useRef, useState } from "react";
import NotFound from "../../components/NotFound";

import { useTheme } from '@material-ui/styles';
import { green, grey } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Card, CardContent, CardMedia, Chip, Fab, Grid, TextField, Typography, InputLabel, MenuItem, Select } from "@material-ui/core";
import OutlinedInput from '@mui/material/OutlinedInput';

import { useCookies } from 'react-cookie';
import { useJwt } from "react-jwt";
import api from "../../services/api";
import Loading from "../../components/Loading";
import { SnackbarProvider } from "notistack";
import DateP from "../../components/DateP";
import { MTableToolbar, MTableEditField } from 'material-table';
import { Link as LinkW } from '@mui/material/';
//import GeneralData from "../../components/GeneralData";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TableField from "../../components/TableField";
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import EmailModal from "../../components/EmailModal";
import Delete from "@mui/icons-material/Delete";

const urlsSystems = {
  "ICCEM": "https://camaradecomercioempresarial.org/wp-admin/admin-ajax.php?action=get_cert",
  "Fundacion CCE": "https://camaradecomercioempresarial.org/wp-admin/admin-ajax.php?action=get_cert",
  "CCE": "https://camaradecomercioempresarial.org/wp-admin/admin-ajax.php?action=get_cert",
  "Adeci": "https://asociaciondedesarrollo.com/wp-admin/admin-ajax.php?action=get_cert",
  "Camara Nacional": "https://camaranacionaldenegocios.com/wp-admin/admin-ajax.php?action=get_cert",
  "Gremios Profesional": "https://gremiosprofesionales.com/wp-admin/admin-ajax.php?action=get_cert",
  "Consejos Iberoamericanos": "https://consejosiberoamericanos.com/wp-admin/admin-ajax.php?action=get_cert",
  "Escuela Latinoamericana": "https://escuelasuperiordeposgrado.com//wp-admin/admin-ajax.php?action=get_cert",
  "CCEB": "https://camaradecomercipempresarial.com//wp-admin/admin-ajax.php?action=get_cert",
  "Fundacion CO": "https://inicio.camaradecomercioempresarial.org/wp-admin/admin-ajax.php?action=get_cert",
  "Encotur": "https://inicio.camaradecomercioempresarial.org/wp-admin/admin-ajax.php?action=get_cert",
}
const Principal = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState('1');
  const [selectedPay, setSelectedPay] = useState(null);

  const [cookies] = useCookies(['token']);
  const { decodedToken, isExpired } = useJwt(cookies?.token);
  const [cursos, setCursos] = useState([]);
  const [cursosPay, setCursosPay] = useState([]);
  const [tokenJwt] = useState(cookies.token);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const [membershipList, setMembershipList] = useState([]);
  const [systemList, setSystemList] = useState([]);

  const [dateIni, setDateIni] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());

  const [sys, setSys] = useState(null);

  const msg = useRef();

  const [user_nicename, setUser_nicename] = useState('');
  const [user_email, setUser_email] = useState('');
  const [user_password, setUser_password] = useState('');
  const [display_name, setDisplay_name] = useState('');
  const [memb, setMemb] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [sysName, setSysName] = useState('');
  const [file, setFile] = useState(null);
  const [massiveFile, setMassiveFile] = useState(null);
  const [massiveUsers, setMassiveUsers] = useState(null);
  const [filesCheck, setFilesCheck] = useState(null);

  const [open, setOpen] = useState(false);
  const [openMassive, setOpenMassive] = useState(false);

  const [openEmail, setOpenEmail] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [toList, setToList] = useState([]);

  const [certificadosUser, setCertificadosUser] = useState([]);
  const [currentUser, setCurrentUser] = useState({
    systemName: '',
    user_id: '',
    percent: ''
  });
  const [openCert, setOpenCert] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectPay = (event, payInfo) => {
    setSelectedPay(payInfo);

  };


  useEffect(() => {
    function checkPermList(name) {
      let arrPermList = [
        '',
        'Usuarios_Eliminar',
        'Usuarios_Agregar',
        'Ver_Membresias',
        'Revisar_Membresias',
        'Activar_Membresias',
        'Perfiles_Agregar',
        'Perfiles_Eliminar',
        'Permisos_Agregar',
        'Edita membresia',
        'Agrega Registro',
        'Usuarios_Modificar',
      ]
      return arrPermList.indexOf(name) >= 0;
    }
    async function getCursos(token, perm) {
      try {
        let req = { token: token, system: perm }
        let datos = await api.post('api/listMails/', req);

        let data = datos.data.emails;
        console.log(data);
        setEmailList(data);

      } catch (err) {
        console.log(err);
        msg.current.enqueueSnackbar("Ocurrio un error al consultar los correos del sistema", {
          variant: "error",
        });
      }
      try {
        let req = { token: token, groups: perm, dateini: dateIni, dateend: dateEnd }
        let datos = await api.post('api/courselist/', req);

        let data = datos.data;
        setCursos(data);
      } catch (err) {
        console.log(err);
        msg.current.enqueueSnackbar("Ocurrio un error al consultar las membresias", {
          variant: "error",
        });
      }
      try {
        let req = { token: token, groups: perm, isPay: [1], dateini: dateIni, dateend: dateEnd }
        let { data } = await api.post('api/courselist/', req);

        setCursosPay(data);

        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        msg.current.enqueueSnackbar("Ocurrio un error al consultar las membresias de pago", {
          variant: "error",
        });
      }
    }
    async function getMembership(token, perm) {


      let arrMem = {}, arrSystem = {};
      try {

        for (var i = 0; i < perm.length; i++) {

          if (!checkPermList(perm[i])) {
            try {
              let req = { token: token, systemName: perm[i] }
              let { data } = await api.post('api/cpanellevel/', req);
              arrMem[perm[i]] = data;
              arrSystem[perm[i]] = perm[i];
            } catch (e) {
              console.log(perm[i]);
              console.log(e);
            }
          }

        }

      } catch (err) {
        console.log(err);
        msg.current.enqueueSnackbar("Ocurrio un error al consultar la lista de membresias", {
          variant: "error",
        });
      }

      setSystemList(arrSystem);
      setMembershipList(arrMem);
    }

    if (tokenJwt && decodedToken && reload) {
      let { userInfo } = decodedToken;
      setRoles(userInfo.roles);
      getCursos(tokenJwt, userInfo.roles);
      getMembership(tokenJwt, userInfo.roles);
    }
    setReload(false);
  }, [tokenJwt, decodedToken, reload, sys]);

  useEffect(() => {
    setReload(true);
  }, [dateIni, dateEnd]);

  const changeStatus = async (membresias, status, status2, status_member) => {

    setLoading(true);


    try {
      await Promise.all(membresias.map(async (membresia, index) => {

        return updateMember(membresia.name, membresia.username, membresia.email, status, status2, status_member, membresia.generalMemb.memb, membresia.membership, membresia.comment, membresia.group)


      }));


      setLoading(true);
      setReload(true);

    } catch (err) {

      console.log(err);

      setLoading(true);
    }


  }

  const updateMember = async (name, username, email, status, status2, status_member, memberid, memeber_old, comment, group) => {
    try {
      let req = {

        token: tokenJwt,
        status: status,
        status2: status2,
        status_member: status_member,
        email: email,
        comment: comment,
        username: username,
        membership_id: memberid,
        membership_old: memeber_old,
        systemName: group
      }


      let { data } = await api.post('api/cpanelmember/update', req);
      if (data.status !== 'Error') {

        if (status == 'Aprobada') {
          msg.current.enqueueSnackbar("Membresia activada para el usuario: " + username, {
            variant: "success",
          });
          return data;
        }
        if (status2 == 'Aprobada') {
          msg.current.enqueueSnackbar("Se aprobó la revisión del usuario " + username, {
            variant: "success",
          });
        }


        console.log(data.msg);
      } else {
        console.log(data.msg);
        if (data.msg[0]) {
          msg.current.enqueueSnackbar("Error al activar membresia del usuario: " + username, {
            variant: "error",
          });
        } else {
          msg.current.enqueueSnackbar("Error al activar membresia del usuario: " + username, {
            variant: "error",
          });
        }

      }

      return data;
    } catch (err) {
      msg.current.enqueueSnackbar("No se pudo activar la membresia de " + username, {
        variant: "error",
      });


    }
  }

  const setUsersTo = (usersSelected) => {

    let userMails = [];
    for (let i = 0; i < usersSelected.length; i++) {

      console.log(usersSelected[i])
      userMails.push(usersSelected[i].email);
    }
    setToList(userMails);
    setOpenEmail(true);
  }

  const setDeleteUsers = async (usersSelected) => {
    setLoading(true);

    let userMails = [];
    for (let i = 0; i < usersSelected.length; i++) {


      userMails.push({ email: usersSelected[i].email, username: usersSelected[i].username, systemName: usersSelected[i].group });
    }


    try {

      let req = { token: tokenJwt, users: userMails }
      console.log(req);
      await api.post('api/cpanelmember/delete', req);

      msg.current.enqueueSnackbar("Usuarios eliminados correctamente", {
        variant: "success",
      });
      setReload(true);

    } catch (err) {
      msg.current.enqueueSnackbar("Ocurrio un error al eliminar los usuarios ", {
        variant: "error",
      });
    }
    setLoading(false);

  }

  const content = () => {


    return (
      <>
        <SnackbarProvider ref={msg} maxSnack={10} />
        <Loading open={loading} />
        <TabContext value={value}  >


          <Tabs
            value={value}
            onChange={handleChange}
            style={{ background: theme.background.default }}
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab wrapped label="Pagos en Efectivo" value="1" />
            <Tab wrapped label="Pagos con tarjeta" value="2" />

          </Tabs>

          <TabPanel value="1">

            <TablaGeneral
              title={"Membresias"}

              columns={[
                {
                  title: 'Nombre', field: 'name', editable: checkPermission('Edita membresia') ? 'true' : 'never', render: (rowData) => {
                    return <TableField value={rowData.name} width={220} />
                  }
                },
                {
                  title: 'Usuario', field: 'username', editable: 'never', render: (rowData) => {
                    return <TableField value={rowData.username} width={120} />
                  }
                },
                {
                  title: 'Correo', field: 'email', editable: checkPermission('Edita membresia') ? 'true' : 'never', render: (rowData) => {
                    return <TableField value={rowData.email} />
                  }
                },
                {
                  title: '', field: 'phone', editable: 'never', render: (rowData) => {
                    return rowData.phone && rowData.phone != '' ?
                      <><LinkW target="_blank" rel="noopener" href={"https://wa.me/" + rowData.phone}><WhatsAppIcon color="success" /></LinkW></> :
                      '';
                  }
                },

                {
                  title: 'Teléfono', field: 'phone', editable: checkPermission('Edita membresia') ? 'true' : 'never', render: (rowData) => {
                    return rowData.phone && rowData.phone != '' ?
                      <TableField value={rowData.phone} width={90} /> :
                      <TableField value={'Sin teléfono'} width={90} />;
                  }
                },
                {
                  title: 'Imprimir Certificado', field: 'name', editable: 'never', render: (rowData) => (
                    <>
                      <Button
                        onClick={async () => {
                          let userObj = {
                            systemName: rowData.generalMemb.systemName,
                            user_id: rowData.customer_id,
                            percent: "70"
                          }
                          setOpenCert(true);
                          setCurrentUser(userObj);
                          let req = { token: tokenJwt, systemName: rowData.generalMemb.systemName, user_id: rowData.customer_id, percent: 70 }

                          let cursosTemp = await api.post('api/certList/', req);

                          setCertificadosUser(cursosTemp.data);

                        }}
                      >
                        Ver certificados
                      </Button>
                    </>
                  )
                },
                {
                  title: 'País', field: 'country', editable: checkPermission('Edita membresia') ? 'true' : 'never', render: (rowData) => {


                    return <>{rowData.country && rowData.country != '' ? <TableField value={rowData.country} width={50} />
                      : <TableField value={'N/A'} width={50} />}</>

                  }
                },
                {
                  title: 'Membresia',
                  field: 'generalMemb',
                  editable: checkPermission('Edita membresia') ? 'true' : 'never',

                  render: (rowData) => {

                    let memb = "";
                    membershipList[rowData.generalMemb.systemName]?.map((prof) => {
                      if (rowData.generalMemb.memb == prof.id) {
                        memb = prof.name;
                      }

                    })
                    return <TableField value={memb ? memb : 'N/A'} width={100} />


                  },
                  /*customFilterAndSearch: (term, rowData) => {
                    console.log(term);
                    console.log(rowData);
                    let filterPass = false;
                    membershipList[rowData.generalMemb.systemName]?.map((prof) => {
                      if(rowData.generalMemb.memb == prof.id){
                        
                        filterPass =  prof.name.includes(term) != -1;
                      }
                   
                   })
                    return false;
                  },*/

                  /*filterComponent: (props) => (
                    <>
                      
                      <input placeholder="custom" />
                    </>
                  ),*/
                },
                {
                  title: 'Sistema', field: 'group', editable: 'never', filterOnItemSelect: true, lookup: systemList, render: (rowData) => {
                    return <TableField value={rowData.group} width={100} />
                  }
                },
                {
                  title: 'Código de envio COURIER', editable: checkPermission('Edita membresia') ? 'true' : 'never', field: 'certID', width: 500, render: (rowData) => {

                    return <TableField value={rowData.certID ? rowData.certID : 'Sin código'} width={70} />


                  },
                },
                {
                  title: 'Nombre COURIER', editable: checkPermission('Edita membresia') ? 'true' : 'never', field: 'certName', width: 500, render: (rowData) => {

                    return <TableField value={rowData.certName ? rowData.certName : 'Sin nombre'} width={70} />


                  },
                },
                /* { title: '',editable: 'never', field:'certUrl', width: 200,render: (rowData) => {
                    
                    
                   return (
                    <>{rowData.certUrl ?
                       <Link to={{ pathname: rowData.certUrl }} target="_blank" ><SaveIcon  sx={{ color: theme.palette.secondary.main}} /></Link>
                       :
                       ''
                       }
                     </>
                   )
                  
                 },},
                 { title: 'Certificado',editable: checkPermission('Edita membresia')? 'true':'never', field:'certUrl', width: 200,render: (rowData) => {
                    
                    
                   return (
                    <>
                      
                       <TableField value={rowData.certUrl ? 'Con certificado' : 'Sin certificado'} width={70}/>
                       
                     </>
                   )
                  
                 },},*/
                {
                  title: 'Comentario', field: 'comment', render: (rowData) => {

                    return <TableField value={rowData.comment ? rowData.comment : 'Sin observaciones'} isMultiline width={200} />


                  },
                },

                {
                  title: 'Fecha', field: 'createdAt', editable: 'never',

                  render: (rowData) => {
                    let date = new Date(rowData.createdAt);
                    let day = date.getDate()
                    let month = date.getMonth() + 1
                    let year = date.getFullYear()

                    let newM = month + "";
                    if (newM.length == 1) {
                      newM = "0" + newM;
                    }

                    let newD = day + "";
                    if (newD.length == 1) {
                      newD = "0" + newD;
                    }

                    return <TableField value={`${newD}-${newM}-${year}`} width={100} />

                  }
                },
                {
                  title: "Soporte",
                  field: "status2",
                  editable: 'never',
                  filterOnItemSelect: true,
                  lookup: { 'Pendiente': 'Pendiente', 'Aprobada': 'Aprobada' },
                  render: (rowData) =>
                    rowData && (
                      rowData.status2 === "Pendiente" ?
                        <Chip label={rowData.status2} color="error" style={{ background: red[400] }} />


                        :
                        <Chip label={rowData.status2} color="success" style={{ background: green[400] }} />
                    )
                },
                {
                  title: "Plataforma",
                  field: "status",
                  editable: 'never',
                  filterOnItemSelect: true,
                  lookup: { 'Pendiente': 'Pendiente', 'Aprobada': 'Aprobada' },
                  render: (rowData) =>
                    rowData && (
                      rowData.status === "Pendiente" ?
                        <Chip label={rowData.status} color="error" style={{ background: red[400] }} />


                        :
                        <Chip label={rowData.status} color="success" style={{ background: green[400] }} />
                    )
                },
                {
                  title: "Comprobante",
                  field: "utlImage",
                  editable: checkPermission('Edita membresia') ? 'true' : 'never',
                  export: false,
                  render: (rowData) =>
                    <TableField value={rowData.utlImage ? 'Con certificado' : 'Sin certificado'} width={70} />
                },
                {
                  title: "",
                  field: "utlImage",
                  editable: 'never',
                  export: false,
                  render: (rowData) => {
                    return (
                      <>{rowData.utlImage ?
                        <Link to={{ pathname: rowData.utlImage }} target="_blank" >Revisar</Link>
                        :
                        ''
                      }</>
                    )

                  }
                },
                /* {
                   title: "Revisar",
                   field: "utlImage",
                   editable: 'never',
                   export: false,
                   render: (rowData) =>
                       rowData && (
                         <Button onClick={() => handleSelectPay(null,rowData)}>Detalles</Button>
                       
                       )
                   },*/



              ]}
              data={cursos}
              options={{
                exportButton: true,
                selection: true,
                headerStyle: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText
                },
                maxBodyHeight: 700,

              }}
              actions={[
                {
                  icon: () => <Button>Aprobar revisión</Button>,
                  hidden: roles.indexOf('Revisar_Membresias') == -1,
                  tooltip: 'Aprobar revisión',
                  onClick: (event, rowData) => { changeStatus(rowData, 'Pendiente', 'Aprobada', 'inactive') }
                },
                {
                  icon: () => <Button>Activar membresia</Button>,
                  hidden: roles.indexOf('Activar_Membresias') == -1,
                  tooltip: 'Activar membresia',
                  onClick: (event, rowData) => { changeStatus(rowData, 'Aprobada', 'Aprobada', 'active') }
                },
                {
                  icon: () => <Button>Enviar correo</Button>,
                  hidden: roles.indexOf('Activar_Membresias') == -1,
                  tooltip: 'Abre una ventana para la configuración del correo a enviar',
                  onClick: (event, rowData) => { setUsersTo(rowData) }
                },
                {
                  icon: () => <Delete></Delete>,
                  hidden: roles.indexOf('Eliminar_Registros') == -1,
                  tooltip: 'Elimina los usuarios seleccionados',
                  onClick: (event, rowData) => { setDeleteUsers(rowData) }
                },

              ]}
              cellEditable={{
                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                  return new Promise(async (resolve, reject) => {



                    if (columnDef.field == 'certUrl' || columnDef.field == 'utlImage') {

                      if (columnDef.field == 'certUrl') {
                        const url = await uploadCert(rowData, newValue, 'api/cpanelmember/uploadCert');
                        rowData[columnDef.field] = url;
                      }
                      if (columnDef.field == 'utlImage') {
                        const url = await uploadCert(rowData, newValue, 'api/cpanelmember/uploadComp');
                        rowData[columnDef.field] = url;
                      }

                    } else if (columnDef.field != 'generalMemb') {
                      rowData[columnDef.field] = newValue;

                      if (newValue) {
                        try {
                          let objUpdate = {};

                          objUpdate[columnDef.field] = newValue;
                          let req = {
                            token: tokenJwt,
                            username: rowData.username,
                            membership: rowData.membership,
                            systemName: rowData.group,
                            objUpdate: objUpdate,
                            userID: rowData.customer_id
                          }

                          if (columnDef.field == 'phone') {
                            req.meta_key = 'celular';
                            req.meta_value = newValue;
                          }

                          if (columnDef.field == 'country') {
                            req.meta_key = 'pais';
                            req.meta_value = newValue;
                          }
                          let { data } = await api.post('api/cpanelmember/updatereginfo/', req);

                          if (data.status == 'Error') {
                            msg.current.enqueueSnackbar("Error al actualizar registro", {
                              variant: "error",
                            });
                            return data;
                          }

                          msg.current.enqueueSnackbar("Se actualizó el registro ", {
                            variant: "success",
                          });
                        } catch (err) {
                          console.log(err);
                          msg.current.enqueueSnackbar("Error al actualizar registro", {
                            variant: "error",
                          });
                        }

                      } else {
                        rowData[columnDef.field] = oldValue;
                        msg.current.enqueueSnackbar("El campo no puede estar vacio", {
                          variant: "warning",
                        });
                      }

                    } else {

                      rowData[columnDef.field] = newValue;
                    }
                    setTimeout(resolve, 100);
                  });
                }
              }}
              components={{

                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    <div style={{ padding: '0px 10px' }}>
                      <DateP text={"Fecha inicio"} value={dateIni} setValue={(val) => { setDateIni(val) }} />
                      <DateP text={"Fecha final"} value={dateEnd} setValue={(val) => { setDateEnd(val); }} />
                    </div>
                  </div>
                ),
                EditField: fieldProps => {

                  if (fieldProps.columnDef.field == 'certUrl') {
                    return (<label htmlFor="certFile">
                      <input
                        id="certFile"
                        type="file"
                        hidden
                        onChange={(e) => { setFilesCheck(e.target.files[0]); fieldProps.onChange(e.target.files[0]) }}
                      />
                      <LoadingButton
                        variant={"outlined"}

                        fullWidth
                        component="span"
                        sx={{ color: theme.palette.secondary.main }}
                        endIcon={<SaveIcon />}
                        loadingPosition="end"

                      >
                        {!filesCheck ? "Subir certificado" : filesCheck.name}

                      </LoadingButton >
                    </label>)
                  }
                  if (fieldProps.columnDef.field == 'utlImage') {
                    return (<label htmlFor="certFile">
                      <input
                        id="certFile"
                        type="file"
                        hidden
                        onChange={(e) => { setFilesCheck(e.target.files[0]); fieldProps.onChange(e.target.files[0]) }}
                      />
                      <LoadingButton
                        variant={"outlined"}

                        fullWidth
                        component="span"
                        sx={{ color: theme.palette.secondary.main }}
                        endIcon={<SaveIcon />}
                        loadingPosition="end"

                      >
                        {!filesCheck ? "Subir comprobante" : filesCheck.name}

                      </LoadingButton >
                    </label>)
                  }
                  if (fieldProps.columnDef.field == 'generalMemb') {

                    return (
                      <Box sx={{ m: 2 }} >

                        <Select
                          labelId="perfilLabel"
                          id="Perfil"
                          fullWidth
                          label="Perfil"
                          value={fieldProps.value.memb}
                          onChange={(e) => fieldProps.onChange({ memb: e.target.value, systemName: fieldProps.value.systemName })}
                          input={<OutlinedInput label="Perfil" />}
                        >
                          {membershipList[fieldProps.value.systemName].map((prof) => {
                            return (<MenuItem key={prof.id} value={prof.id}>{prof.name}</MenuItem>)

                          })}

                        </Select>

                      </Box>
                    )
                  }
                  if (fieldProps.columnDef.field == 'comment') {
                    return (
                      <Box sx={{ m: 2 }} >

                        <TextField
                          style={{ width: 200 }}
                          id="outlined-basic"
                          label={fieldProps.columnDef.title}
                          variant="outlined"
                          multiline
                          rows={4}
                          defaultValue={fieldProps.value}
                          onChange={(e) => fieldProps.onChange(e.target.value)}
                        />

                      </Box>)

                  }
                  return (
                    <Box sx={{ m: 2 }} >

                      <TextField
                        style={{ width: 200 }} id="outlined-basic"
                        label={fieldProps.columnDef.title}
                        variant="outlined"
                        defaultValue={fieldProps.value}
                        onChange={(e) => fieldProps.onChange(e.target.value)}
                      />

                    </Box>)
                  //return <MTableEditField {...{ ...fieldProps, value: fieldProps.value || '' }} />


                },
              }}
            />

          </TabPanel>
          <TabPanel value="2">
            <TablaGeneral
              title={"Usuarios pago con tarjeta"}
              columns={[
                {
                  title: 'Nombre', field: 'name', editable: 'never', render: (rowData) => {
                    return <TableField value={rowData.name} width={220} />
                  }
                },
                {
                  title: 'Usuario', field: 'username', editable: 'never', render: (rowData) => {
                    return <TableField value={rowData.username} width={120} />
                  }
                },
                {
                  title: 'Correo', field: 'email', editable: 'never', render: (rowData) => {
                    return <TableField value={rowData.email} />
                  }
                },
                {
                  title: '', field: 'phone', editable: 'never', render: (rowData) => {
                    return rowData.phone && rowData.phone != '' ?
                      <><LinkW target="_blank" rel="noopener" href={"https://wa.me/" + rowData.phone}><WhatsAppIcon color="success" /></LinkW></> :
                      '';
                  }
                },
                {
                  title: 'Teléfono', field: 'phone', editable: 'never', render: (rowData) => {
                    return rowData.phone && rowData.phone != '' ?
                      <TableField value={rowData.phone} width={90} /> :
                      <TableField value={'Sin teléfono'} width={90} />;
                  }
                },
                {
                  title: 'País', field: 'country', editable: 'never', render: (rowData) => {


                    return <>{rowData.country && rowData.country != '' ? <TableField value={rowData.country} width={50} />
                      : <TableField value={'N/A'} width={50} />}</>

                  }
                },
                {
                  title: 'Membresia',
                  field: 'generalMemb',
                  editable: 'never',

                  render: (rowData) => {

                    let memb = "";
                    membershipList[rowData.generalMemb.systemName]?.map((prof) => {
                      if (rowData.generalMemb.memb == prof.id) {
                        memb = prof.name;
                      }

                    })
                    return <TableField value={memb ? memb : 'N/A'} width={100} />


                  },
                  /*customFilterAndSearch: (term, rowData) => {
                    console.log(term);
                    console.log(rowData);
                    let filterPass = false;
                    membershipList[rowData.generalMemb.systemName]?.map((prof) => {
                      if(rowData.generalMemb.memb == prof.id){
                        
                        filterPass =  prof.name.includes(term) != -1;
                      }
                   
                   })
                    return false;
                  },*/

                  /*filterComponent: (props) => (
                    <>
                      
                      <input placeholder="custom" />
                    </>
                  ),*/
                },
                {
                  title: 'Sistema', field: 'group', editable: 'never', filterOnItemSelect: true, lookup: systemList, render: (rowData) => {
                    return <TableField value={rowData.group} width={100} />
                  }
                },
                {
                  title: 'Imprimir Certificado', field: 'name', editable: 'never', render: (rowData) => (
                    <>
                      <Button
                        onClick={async () => {
                          console.log(rowData)
                          let userObj = {
                            systemName: rowData.group,
                            user_id: rowData.customer_id,
                            percent: "70"
                          }
                          console.log(rowData)
                          setOpenCert(true);
                          setCurrentUser(userObj);
                          let req = { token: tokenJwt, systemName: rowData.group, user_id: rowData.customer_id, percent: 70 }
                          console.log(req)
                          let cursosTemp = await api.post('api/certList/', req);
                          console.log(cursosTemp)
                          setCertificadosUser(cursosTemp.data);

                        }}
                      >
                        Ver certificados
                      </Button>
                    </>
                  )
                },
                {
                  title: "Estatus membresia",
                  field: "status",
                  editable: 'never',
                  filterOnItemSelect: true,
                  lookup: { 'Activa': 'Activa', 'Pendiente': 'Pendiente', 'Cancelada': 'Cancelada', 'Inactiva': 'Inactiva' },
                  /* filterComponent: (props) => (
                     <>
                       <SelectFilter
                         title={"Estatus membresia"}
                         fi
                        />
                     </>
                   ),*/
                  /* customFilterAndSearch: (term, rowData) => {
                     let filterPass = false;
                     console.log(term);
                     console.log(rowData);
                     membershipList[rowData.generalMemb.systemName]?.map((prof) => {
                       if(rowData.generalMemb.memb == prof.id){
                         
                         filterPass =  prof.name.includes(term) != -1;
                       }
                    
                    })
                     return filterPass;
                   },*/
                  render: (rowData) =>
                    rowData && (
                      rowData.status === "Activa" ?
                        <Chip label={rowData.status} color="success" style={{ background: green[400] }} />


                        :
                        rowData.status === "Pendiente" ?
                          <Chip label={rowData.status} color="success" style={{ background: red[400] }} />


                          :
                          <Chip label={rowData.status} color="error" style={{ background: grey[400] }} />

                    )
                },
                {
                  title: "Estatus pago",
                  field: "status2",
                  editable: 'never',
                  filterOnItemSelect: true,
                  lookup: { 'success': 'Exitoso', 'cancelled': 'Cancelado' },
                  render: (rowData) =>
                    rowData && (
                      rowData.status2 === "success" ?
                        <Chip label={rowData.status2} color="error" style={{ background: green[400] }} />


                        :
                        <Chip label={rowData.status2} color="success" style={{ background: grey[400] }} />
                    )
                },
                {/*
                  title: "Revisar",
                  field: "utlImage",
                  editable: 'never',
                  export: false,
                  render: (rowData) =>
                      rowData && (
                        <Button onClick={() => handleSelectPay(null,rowData)}>Detalles</Button>
                      
                      )
                      */},



              ]}
              data={cursosPay}
              options={{
                exportButton: true,
                selection: true,
                headerStyle: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText
                },


              }}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    <div style={{ padding: '0px 10px' }}>
                      <DateP text={"Fecha inicio"} value={dateIni} setValue={(val) => { setDateIni(val) }} />
                      <DateP text={"Fecha final"} value={dateEnd} setValue={(val) => { setDateEnd(val); }} />
                    </div>

                  </div>
                ),
              }}
            />
          </TabPanel>
          <TabPanel value="3"><NotFound /></TabPanel>
        </TabContext>


      </>
    );
  }
  const handleOpen = (openVal) => {
    setOpen(openVal);
    setSysName('');
    setUser_nicename('');
    setUser_email('');
    setUser_password('');
    setDisplay_name('')
    setMemb('');
    setPhone('');
    setCountry('');
    setFile('');
  }

  const uploadCert = async (rowData, fileUpload, url) => {
    const formData = new FormData();

    formData.append('file', fileUpload)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'x-access-token': tokenJwt
      }
    }

    formData.append('systemName', rowData.group)
    formData.append('username', rowData.username)
    formData.append('email', rowData.email)
    formData.append('membership', rowData.membership)


    let { data } = await api.post(url, formData, config);

    setFilesCheck(null);
    if (data.status == 'Error') {
      msg.current.enqueueSnackbar("Error al subir certificado", {
        variant: "error",
      });
      return null;
    } else {
      msg.current.enqueueSnackbar("Certificado actualizado", {
        variant: "success",
      });
      console.log(data.msg[2].url);
      if (data.msg[2].url?.data?.webViewLink) {
        return data.msg[2].url.data.webViewLink;
      } else {
        return data.msg[2].url;
      }

    }

  }
  const errorMsg = (msgText) => {
    msg.current.enqueueSnackbar(msgText, {
      variant: "error",
    });
  }
  const addNewReg = async () => {
    if (!display_name) {
      errorMsg("El nombre requerido");
      return false;
    }
    if (!user_nicename) {
      errorMsg("El nombre de usuario es requerido");
      return false;
    }
    if (!user_email) {
      errorMsg("El correo es requerido");
      return false;
    }
    if (!user_password) {
      errorMsg("El password es requerido");
      return false;
    }
    if (!phone) {
      errorMsg("El teléfono es requerido");
      return false;
    }
    if (!country) {
      errorMsg("El país es requerido");
      return false;
    }
    if (!sysName || sysName == '') {
      errorMsg("El sistema es requerido");
      return false;
    }
    if (!memb || memb == '') {
      errorMsg("La membresía es requerida");
      return false;
    }
    if (!file || file == '') {
      errorMsg("El comprobante de pago es requerido");
      return false;
    }
    try {
      setLoading(true);
      handleOpen(false);

      const formData = new FormData();

      formData.append('file', file)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'x-access-token': tokenJwt
        }
      }

      formData.append('systemName', sysName)
      formData.append('password', user_password)
      formData.append('user_nicename', user_nicename)
      formData.append('user_email', user_email)
      formData.append('display_name', display_name)
      formData.append('phone', phone)
      formData.append('country', country)
      formData.append('memb', memb)

      let { data } = await api.post('api/cpanelmember/addMember', formData, config);


      if (data.status == 'Error') {
        msg.current.enqueueSnackbar("Error al crear usuario", {
          variant: "error",
        });

      } else {
        msg.current.enqueueSnackbar("Usuario creado", {
          variant: "success",
        });
      }


      setLoading(false);
      setReload(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      handleOpen(false);
      msg.current.enqueueSnackbar("Ocurrio un error al agregar el usuario", {
        variant: "error",
      });
    }
  }
  const modalAddReg = () => {
    return (
      <Dialog onClose={() => handleOpen(false)} open={open}>
        <DialogTitle>Agregar usuario</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingresa los datos solicitados para poder generar un nuevo usuario
          </DialogContentText>
          <Box sx={{ m: 2 }} >

            <TextField
              autoFocus
              margin="dense"
              id="displayName"
              label="Nombre completo"
              value={display_name}
              onChange={(e) => setDisplay_name(e.target.value)}
              fullWidth
              variant="standard"
            />
          </Box>
          <Box sx={{ m: 2 }} >

            <TextField
              autoFocus
              margin="dense"
              id="user_email"
              label="Correo"
              value={user_email}
              onChange={(e) => setUser_email(e.target.value)}
              fullWidth
              variant="standard"
            />

          </Box>
          <Box sx={{ m: 2 }} >

            <TextField
              autoFocus
              margin="dense"
              id="user_password"
              label="Contraseña"
              type="password"
              value={user_password}
              onChange={(e) => setUser_password(e.target.value)}
              fullWidth
              variant="standard"
            />
          </Box>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ m: 2 }} >

                <TextField
                  autoFocus
                  margin="dense"
                  id="user_nicename"
                  label="Nombre de usuario"
                  value={user_nicename}
                  onChange={(e) => setUser_nicename(e.target.value)}
                  fullWidth
                  variant="standard"
                />
              </Box>

            </Grid>
            <Grid item md={6}>
              <Box sx={{ m: 2 }} >

                <TextField
                  autoFocus
                  margin="dense"
                  id="phone"
                  label="Teléfono"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                  variant="standard"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <Box sx={{ m: 2 }} >
                <InputLabel id="perfilLabel">Sistema</InputLabel>
                <Select
                  labelId="perfilLabel"
                  id="sysName"
                  fullWidth
                  label="Perfil"
                  value={sysName}
                  onChange={(e) => { setSysName(e.target.value); }}
                  input={<OutlinedInput label="Perfil" />}
                >
                  {Object.keys(systemList).map((prof) => {
                    return (<MenuItem key={prof} value={prof}>{prof}</MenuItem>)

                  })}

                </Select>

              </Box>

            </Grid>
            <Grid item md={6}>
              <Box sx={{ m: 2 }} >
                <InputLabel id="memlabel">Membresía</InputLabel>
                <Select
                  labelId="memlabel"
                  id="memb"
                  fullWidth
                  label="Membresia"
                  value={memb}
                  onChange={(e) => { setMemb(e.target.value) }}
                  input={<OutlinedInput label="Perfil" />}
                >
                  {membershipList[sysName]?.map((membTemp) => {
                    return (<MenuItem key={membTemp.id} value={membTemp.id}>{membTemp.name}</MenuItem>)

                  })}

                </Select>

              </Box>
            </Grid>
          </Grid>


          <Box sx={{ m: 2 }} >

            <TextField
              autoFocus
              margin="dense"
              id="country"
              label="País"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              fullWidth
              variant="standard"
            />
          </Box>



          <Box sx={{ m: 2 }} >
            <Button
              variant={file ? "contained" : "outlined"}
              component="label"
              fullWidth
              style={file ? { background: green[500], color: '#FFFFFF' } : {}}
              color={file ? "success" : "secondary"}
              endIcon={file ? <CheckCircleIcon /> : ''}
            >
              {file ? file.name : "Subir comprobante"}
              <input
                type="file"
                hidden
                onChange={(e) => { setFile(e.target.files[0]); console.log(e.target.files[0]); }}
              />
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpen(false)}>Cancel</Button>

          <Button variant="contained" style={{ color: theme.palette.primary.contrastText, background: theme.palette.primary.main }} onClick={() => addNewReg()}>Agregar</Button>


        </DialogActions>
      </Dialog>
    )
  }

  const modalCert = () => {
    return (
      <Dialog onClose={() => setOpenCert(false)} open={openCert}>
        <DialogTitle>Certificados</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Certificados disponibles del usuario
          </DialogContentText>

          {
            certificadosUser?.map((cert) => (
              <Box sx={{ m: 2 }} >
                <Button
                  variant={"outlined"}
                  component="label"
                  fullWidth
                  color={"secondary"}
                  endIcon={file ? <CheckCircleIcon /> : ''}
                  onClick={() => {
                    let url = urlsSystems[currentUser.systemName];
                    url += '&post_id=false';
                    url += '&course_id=' + cert.course_id;
                    url += '&user_id=' + currentUser.user_id;

                    console.log(url);
                    window.open(url, '_blank').focus();
                  }}
                >
                  {cert.wp_post.post_title}

                </Button>
              </Box>
            ))
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCert(false)}>Cerrar</Button>



        </DialogActions>
      </Dialog>
    )
  }

  const modalMassive = () => {
    return (
      <Dialog onClose={() => setOpenMassive(false)} open={openMassive}>
        <DialogTitle>Agregar usuarios CSV</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Adjunta el archivo CSV
          </DialogContentText>


          <Box sx={{ m: 2 }} >
            <Button
              variant={massiveFile ? "contained" : "outlined"}
              component="label"
              fullWidth
              style={massiveFile ? { background: green[500], color: '#FFFFFF' } : {}}
              color={massiveFile ? "success" : "secondary"}
              endIcon={massiveFile ? <CheckCircleIcon /> : ''}
            >
              {massiveFile ? massiveFile.name : "Subir CSV"}
              <input
                type="file"
                hidden
                onChange={(e) => { readMassiveFile(e.target.files[0]); }}
              />
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMassive(false)}>Cancel</Button>

          <Button variant="contained" style={{ color: theme.palette.primary.contrastText, background: theme.palette.primary.main }} onClick={() => addMassiveUsers()}>Agregar</Button>


        </DialogActions>
      </Dialog>
    )
  }

  const validaInfo = (dato) => {
    return dato ? dato : '';
  }
  const readMassiveFile = (file) => {
    setMassiveFile(file);
    const reader = new FileReader();

    reader.onload = function (e) {

      const text = e.target.result;


      csvToArray(text);

    };

    reader.readAsText(file);
  }

  const csvToArray = (str, delimiter = ",") => {

    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    let arrUsers = [];

    rows.map((value) => {
      let userInfo = value.split(delimiter);
      const newUser = {
        'display_name': validaInfo(userInfo[0]),
        'user_nicename': validaInfo(userInfo[1]),
        'user_email': validaInfo(userInfo[2]),
        'phone': validaInfo(userInfo[3]),
        'country': validaInfo(userInfo[4]),
        'memb': validaInfo(userInfo[5]),
        'systemName': validaInfo(userInfo[6]),
        'utlImage': validaInfo(userInfo[7]),
      }
      arrUsers.push(newUser);
    })

    setMassiveUsers(arrUsers);
  }

  const addMassiveUsers = async () => {

    if (!massiveUsers) {
      errorMsg("Debe adjuntar un csv valido");
      return false;
    }
    try {
      setLoading(true);
      setOpenMassive(false);

      const config = {
        headers: {
          'x-access-token': tokenJwt
        }
      }

      let { data } = await api.post('api/cpanelmember/addMemberM', { users: massiveUsers }, config);
      console.log(data);

      if (data.status == 'Error') {
        msg.current.enqueueSnackbar("Error al crear usuarios", {
          variant: "error",
        });

      } else {
        msg.current.enqueueSnackbar("Usuarios creados", {
          variant: "success",
        });
      }

      setLoading(false);

    } catch (err) {
      console.log(err);
      setLoading(false);
      setOpenMassive(false);
      msg.current.enqueueSnackbar("Ocurrio un error al agregar lo susuarios", {
        variant: "error",
      });
    }


  }

  const showPayInfo = () => {

    return (
      <>
        <Box sx={{ m: 2 }}  >
          <Grid container direction={'row'} spacing={12}>

            <Grid item xl={6} md={6} sm={12} xs={12}>
              <Card >
                <CardContent>

                  <Typography variant="h5" component="div">
                    Información
                  </Typography>
                  <Box sx={{ m: 2 }} >

                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Nombre"
                      disabled
                      fullWidth
                      value={selectedPay.name}

                    />
                  </Box>
                  <Box sx={{ m: 2 }} >
                    <TextField
                      autoFocus
                      margin="dense"
                      id="curso"
                      label="Curso"
                      disabled
                      value={selectedPay.idCourse}
                      fullWidth

                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="descripcion"
                      label="Contraseña"
                      disabled
                      value={"Este valor esta pendiente"}
                      fullWidth

                    />
                    <Box sx={{ p: 2, border: '1px dashed grey' }}>
                      <Grid container direction={'row'} spacing={12}>
                        <Grid item xl={6} md={6} sm={12} xs={12}>
                          {
                            selectedPay && (
                              selectedPay.status !== "Pendiente" ?
                                <Chip label={selectedPay.status} color="success" style={{ background: green[500] }} />

                                : <Chip label={selectedPay.status} color="error" style={{ background: red[500] }} />
                            )
                          }

                        </Grid>
                        <Grid item xl={6} md={6} sm={12} xs={12}>
                          <Button>Ver documento</Button>

                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                </CardContent>
              </Card>
            </Grid>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <Card >
                <CardContent>

                  <Typography variant="h5" component="div">
                    Recibo
                  </Typography>

                  {selectedPay?.utlImage.includes(".jpg") || selectedPay?.utlImage.includes(".png") ? <CardMedia
                    component="img"
                    height="290em"
                    image={selectedPay.utlImage}
                    alt="Comprobante"
                  /> : <NotFound showReg={false} msg={"No se pudo cargar la imagen"} />}
                </CardContent>
              </Card>
            </Grid>
          </Grid>


        </Box>

        <Box sx={{ m: 2 }}>
          <Grid container justifyContent="center">

            <Fab color="secondary" aria-label="add" onClick={handleSelectPay}>
              <SettingsBackupRestoreIcon />
            </Fab>
            {selectedPay.status === "Pendiente" && (
              <Fab style={{ backgroundColor: green[500] }} aria-label="add" onClick={handleSelectPay}>
                <DoneIcon />
              </Fab>
            )

            }

          </Grid>
        </Box>
      </>
    )
  }
  const checkPermission = (permission) => {
    return roles.indexOf(permission) >= 0;
  }
  return (
    <>
      {
        !selectedPay ? content() : showPayInfo()
      }
      <EmailModal
        title={"Envio de correo"}
        open={openEmail}
        setOpen={setOpenEmail}
        correos={emailList}
        send={async (data, fileE) => {
          const formData = new FormData();

          formData.append('file', fileE)
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              'x-access-token': tokenJwt
            }
          }

          formData.append('toEmail', toList)
          formData.append('email', data.email)
          formData.append('system', data.system)
          formData.append('subject', data.subject)
          formData.append('msg', data.msg)
          setOpenEmail(false);
          setLoading(true);
          console.log(data.email);
          try {
            console.log(formData);
            await api.patch('api/sendMail/', formData, config);
            msg.current.enqueueSnackbar("Correo enviado correctamente", {
              variant: "success",
            });
            setLoading(false);
          } catch (err) {
            msg.current.enqueueSnackbar("Ocurrio un error al enviar el correo", {
              variant: "error",
            });
            setLoading(false);
          }

          //console.log(data2);
        }}
        to={toList}
        dialogView={msg}
      />
      {
        checkPermission('Agrega Registro') ?
          <>
            <Grid container>
              <Grid item xs={6}>
                <Box sx={{ m: 2 }} boxShadow={0}>
                  <Grid container justifyContent="center" alignItems="center"  >
                    <Grid item xs={12}>
                      <Typography align="center">Agregar Usuario</Typography>
                    </Grid>
                    <Grid item align="center">
                      <Fab color="primary" aria-label="add" onClick={() => { handleOpen(true); }}>
                        <AddIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ m: 2 }} boxShadow={0}>
                  <Grid container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                      <Typography align="center">Agregar usuarios masivos CSV</Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                      <Fab color="primary" aria-label="add" onClick={() => { setOpenMassive(true); }}>
                        <AddIcon />
                      </Fab>
                    </Grid>


                  </Grid>
                </Box>
              </Grid>
            </Grid>

            {modalAddReg()}
            {modalMassive()}

          </>
          :
          ''
      }
      {modalCert()}

    </>

  );
}

export default Principal;
